/*
  	Flaticon icon font: Flaticon
  	Creation date: 04/06/2019 13:51
  	*/

@font-face {
  font-family: 'Flaticon';
  font-display: swap;
  src: url('./Flaticon.eot');
  src: url('./Flaticon.eot?#iefix') format('embedded-opentype'),
    url('./Flaticon.woff2') format('woff2'),
    url('./Flaticon.woff') format('woff'),
    url('./Flaticon.ttf') format('truetype'),
    url('./Flaticon.svg#Flaticon') format('svg');
  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  @font-face {
    font-family: 'Flaticon';
    font-display: swap;
    src: url('./Flaticon.svg#Flaticon') format('svg');
  }
}

[class^='flaticon-']:before,
[class*=' flaticon-']:before,
[class^='flaticon-']:after,
[class*=' flaticon-']:after {
  font-family: Flaticon;
  font-style: normal;
}

.flaticon-emergency-call:before {
  content: '\f100';
}
.flaticon-hospital:before {
  content: '\f101';
}
.flaticon-emergency-call-1:before {
  content: '\f102';
}
.flaticon-add:before {
  content: '\f103';
}
.flaticon-address:before {
  content: '\f104';
}
.flaticon-box:before {
  content: '\f105';
}
.flaticon-delivery:before {
  content: '\f106';
}
.flaticon-followers:before {
  content: '\f107';
}
.flaticon-calendar:before {
  content: '\f108';
}
.flaticon-support:before {
  content: '\f109';
}
.flaticon-salary:before {
  content: '\f10a';
}
.flaticon-bank:before {
  content: '\f10b';
}
.flaticon-check:before {
  content: '\f10c';
}
.flaticon-check-1:before {
  content: '\f10d';
}
.flaticon-user:before {
  content: '\f10e';
}
.flaticon-appointment:before {
  content: '\f10f';
}
.flaticon-cancel:before {
  content: '\f110';
}
.flaticon-schedule:before {
  content: '\f111';
}
.flaticon-logistics:before {
  content: '\f112';
}
.flaticon-pie-chart:before {
  content: '\f113';
}
.flaticon-alarm:before {
  content: '\f114';
}
.flaticon-warning:before {
  content: '\f115';
}
.flaticon-writing:before {
  content: '\f116';
}
.flaticon-support-1:before {
  content: '\f117';
}
.flaticon-calendar-1:before {
  content: '\f118';
}
.flaticon-delivery-truck:before {
  content: '\f119';
}
.flaticon-document:before {
  content: '\f11a';
}
